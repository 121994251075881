var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.application)?_c('div',{},[_c('ControlNavbar'),(_vm.application)?_c('page-header',{attrs:{"title":_vm.application.label}}):_vm._e(),(_vm.application && _vm.application.banner)?_c('div',{staticClass:"banner"},[_c('Banner',{attrs:{"banner":_vm.application.banner}})],1):_vm._e(),(_vm.application != undefined)?_c('div',{staticClass:"container"},[_vm._v(" "+_vm._s(_vm.application.data.fields[0].label)+" "),(true)?_c('a-form',{attrs:{"form":_vm.form,"label-col":{ span: 5 },"wrapper-col":{ span: 12 }},on:{"submit":_vm.handleSubmit}},[_vm._l((_vm.application.data.fields),function(field,index){return _c('div',{key:index},[(field.type === 'text')?_c('a-form-item',{attrs:{"label":field.title}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              ("" + (field.id)),
              {
                rules: [
                  { required: field.required, message: ((field.title) + "!") } ],
              } ]),expression:"[\n              `${field.id}`,\n              {\n                rules: [\n                  { required: field.required, message: `${field.title}!` },\n                ],\n              },\n            ]"}],on:{"change":function (e) {
                _vm.calculation[field.id] = e.target.value;
              }}})],1):(field.type === 'calculation')?_c('a-form-item',{attrs:{"label":field.title}},[_c('p',{attrs:{"id":field.id}},[_vm._v(" "+_vm._s(_vm.calculate(field.data, field.id))+_vm._s(_vm._f("numeral")((_vm.calculation[field.id] || JSON.parse(field.data).error),JSON.parse(field.data).format.replace("#", "0")))+" "+_vm._s(_vm.$session.get("currency"))+" ")])]):(field.type === 'image')?_c('a-form-item',{attrs:{"label":field.title,"required":field.required}},[_c('image-uploader',{attrs:{"fieldData":{
              userId: _vm.$session.get('userId'),
              appId: _vm.application.data.id,
              data: field,
            },"min":field.min || 1,"max":field.max || 1,"deletable":field.deletable || true},on:{"imageUpload":function($event){return _vm.imageUpload($event, field.id)}}})],1):(field.type === 'dsl.plan')?_c('a-form-item',{attrs:{"label":field.title}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              ("" + (field.id)),
              {
                rules: [
                  {
                    required: field.required,
                    message: JSON.parse(field.data).prompt,
                  } ],
              } ]),expression:"[\n              `${field.id}`,\n              {\n                rules: [\n                  {\n                    required: field.required,\n                    message: JSON.parse(field.data).prompt,\n                  },\n                ],\n              },\n            ]"}],attrs:{"placeholder":field.title}},_vm._l((JSON.parse(field.data).objects),function(obj,index){return _c('a-select-option',{key:index,attrs:{"value":obj.value}},[_vm._v(" "+_vm._s(obj.cost)+" "+_vm._s(obj.speed)+" "+_vm._s(obj.limit)+" ")])}),1)],1):(field.type === 'spinner')?_c('a-form-item',{attrs:{"label":field.title}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              ("" + (field.id)),
              {
                rules: [
                  {
                    required: field.required,
                    message: JSON.parse(field.data).prompt,
                  } ],
              } ]),expression:"[\n              `${field.id}`,\n              {\n                rules: [\n                  {\n                    required: field.required,\n                    message: JSON.parse(field.data).prompt,\n                  },\n                ],\n              },\n            ]"}],attrs:{"placeholder":field.title}},_vm._l((JSON.parse(field.data).objects),function(obj,index){return _c('a-select-option',{key:index,attrs:{"value":obj.value}},[_vm._v(" "+_vm._s(obj.text)+" ")])}),1)],1):_c('a-form-item',{attrs:{"label":field.title}},[_c('p',[_vm._v(_vm._s(field.type)+" not implemented")])])],1)}),_c('a-form-item',{attrs:{"wrapper-col":{ span: 12, offset: 5 }}},[_c('a-button',{attrs:{"type":"danger","html-type":"submit"}},[_vm._v(" "+_vm._s(_vm.application.data.submit)+" ")])],1)],2):_vm._e()],1):_vm._e(),_c('loading',{attrs:{"loading":_vm.loader}})],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }