<template>
  <div class="" v-if="application">
    <ControlNavbar/>
    <page-header :title="application.label" v-if="application"/>
    <div class="banner" v-if="application && application.banner">
      <Banner :banner="application.banner"/>
    </div>
    <div class="container" v-if="application != undefined">
      {{ application.data.fields[0].label }}
      <a-form
          v-if="true"
          :form="form"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 12 }"
          @submit="handleSubmit"
      >
        <div v-for="(field, index) in application.data.fields" :key="index">
          <a-form-item :label="field.title" v-if="field.type === 'text'">
            <a-input
                @change="
                (e) => {
                  calculation[field.id] = e.target.value;
                }
              "
                v-decorator="[
                `${field.id}`,
                {
                  rules: [
                    { required: field.required, message: `${field.title}!` },
                  ],
                },
              ]"
            />
          </a-form-item>

          <a-form-item
              :label="field.title"
              v-else-if="field.type === 'calculation'"
          >
            <!-- <a-input
              :disabled="true"
              :value="calculation[field.id] || JSON.parse(field.data).error"
              v-model="calculation[field.id]"
            /> -->

            <p :id="field.id">
              {{
                calculate(field.data, field.id)
              }}{{
                (calculation[field.id] || JSON.parse(field.data).error)
                    | numeral(JSON.parse(field.data).format.replace("#", "0"))
              }}
              {{ $session.get("currency") }}
            </p>
          </a-form-item>
          <a-form-item
              :label="field.title"
              :required="field.required"
              v-else-if="field.type === 'image'"
          >
            <image-uploader
                :fieldData="{
                userId: $session.get('userId'),
                appId: application.data.id,
                data: field,
              }"
                :min="field.min || 1"
                :max="field.max || 1"
                :deletable="field.deletable || true"
                @imageUpload="imageUpload($event, field.id)"
            />
          </a-form-item>
          <a-form-item
              :label="field.title"
              v-else-if="field.type === 'dsl.plan'"
          >
            <a-select
                v-decorator="[
                `${field.id}`,
                {
                  rules: [
                    {
                      required: field.required,
                      message: JSON.parse(field.data).prompt,
                    },
                  ],
                },
              ]"
                :placeholder="field.title"
            >
              <a-select-option
                  v-for="(obj, index) in JSON.parse(field.data).objects"
                  :key="index"
                  :value="obj.value"
              >
                {{ obj.cost }} {{ obj.speed }} {{ obj.limit }}
              </a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item
              :label="field.title"
              v-else-if="field.type === 'spinner'"
          >
            <a-select
                v-decorator="[
                `${field.id}`,
                {
                  rules: [
                    {
                      required: field.required,
                      message: JSON.parse(field.data).prompt,
                    },
                  ],
                },
              ]"
                :placeholder="field.title"
            >
              <a-select-option
                  v-for="(obj, index) in JSON.parse(field.data).objects"
                  :key="index"
                  :value="obj.value"
              >
                {{ obj.text }}
              </a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item :label="field.title" v-else>
            <p>{{ field.type }} not implemented</p>
          </a-form-item>
        </div>
        <a-form-item :wrapper-col="{ span: 12, offset: 5 }">
          <a-button type="danger" html-type="submit">
            {{ application.data.submit }}
          </a-button>
        </a-form-item>
      </a-form>
    </div>
    <loading :loading="loader"/>
  </div>
</template>

<script>
import "bootstrap/dist/css/bootstrap.min.css";
import axios from "axios";
// @ is an alias to /src
import ControlNavbar from "@/components/ControlNavbar";
import Banner from "@/components/Banner";
import Loading from "./Loading.vue";
import ImageUploader from "./ImageUploader.vue";
import {LocaleArabic, LocaleEnglish} from "@/scripts/Locale";
import firebase from "firebase";

export default {
  name: "SpecificApplication",
  components: {ControlNavbar, Banner, Loading, ImageUploader},
  data() {
    return {
      locale: null,
      loader: false,
      calculation: [], //calculation array is an array with the input ids as key with their values, used to complete submit form values for the api
      application: null,
      formLayout: "horizontal",
      form: this.$form.createForm(this, {name: "applicationForm"}),
      images: {},
    };
  },
  methods: {
    checkLocal() {
      if (this.$session.get("lang") && this.$session.get("lang") === "ar") {
        this.locale = LocaleArabic;
      } else {
        this.locale = LocaleEnglish;
      }
    },
    setLoader(show) {
      this.loader = show;
    },
    imageUpload(images, fieldId) {
      // console.log(fieldId, images);
      this.images[`${fieldId}`] = [];
      images.forEach((img) => {
        this.images[`${fieldId}`].push(img.split("base64,")[1]);
      });
    },
    getAxios() {
      return axios.create({
        headers: {
          "Access-Control-Allow-Credentials": true,
          "Access-Control-Allow-Origin": "*",
          language: this.$session.get("lang") || "en",
          itelVersion: "1000",
          uid: "uidtest1.3",
          brand: "Web",
          model: "Web",
          osVersion: "unix",
          operatingSystem: "4",
          sessionId: `${this.$session.get("sessionId")}`,
          token: `${this.$session.get("token")}`,
          deviceId: `${this.$session.get("deviceid")}`,
          sessionCounter: `${this.$session.get("sessionCounter") || 0}`,
          counter: `${this.$session.get("counter")}`,
        },
      });
    },
    handleSubmit(e) {
      var fieldValues = [];
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          console.log("Received values of form: ", values);
          this.application.data.fields.forEach((field) => {
            if (field.type == "calculation") {
              fieldValues.push({
                fieldId: field.id,
                stringValue: this.calculation[field.id],
                imageValues: [],
              });
            } else if (field.type === "image") {
              fieldValues.push({
                fieldId: field.id,
                stringValue: null,
                imageValues: this.images[`${field.id}`],
              });
            } else if (field.type === "text") {
              let input = document.getElementById(
                  `applicationForm_${field.id}`
              );
              fieldValues.push({
                fieldId: field.id,
                stringValue: input.value,
                imageValues: [],
              });
            } else if (field.type === "dsl.plan") {
              fieldValues.push({
                fieldId: field.id,
                stringValue: values[`${field.id}`],
                imageValues: [],
              });
            } else if (field.type === "spinner") {
              fieldValues.push({
                fieldId: field.id,
                stringValue: values[`${field.id}`],
                imageValues: [],
              });
            }
          });
          console.log("application values", fieldValues);
          this.setLoader(true);
          this.getAxios()
              .post(`${this.$session.get("baseURI")}application/submit`, {
                applicationId: this.application.data.id,
                deletedImages: [],
                applicationFieldSubmission: fieldValues,
              })
              .then((result) => {
                this.setLoader(false);
                if (this.checkApiRes(result, {
                  applicationId: this.application.data.id,
                  deletedImages: [],
                  applicationFieldSubmission: fieldValues,
                }, "application/submit", "post")) {
                  // ok
                  console.log("ok", result);
                  this.$message.success(result.data.dialog.message);
                }
              });
        }
      });
    },
    updateValues(e) {
      console.log(e.target.value);
    },
    logout() {
      this.getAxios()
          .post(`${this.$session.get("baseURI")}account/session/deactivate`, {
            sessionId: this.$session.get("sessionId"),
          })
          .then((result) => {
            console.log(this.checkApiResponse(result));
            if (this.checkApiResponse(result)) {
              this.$session.clear();
              firebase
                  .auth()
                  .signOut()
                  .then(() => {
                    this.$router.replace("/login").catch(() => {
                    });
                    console.log("Sign-out successful");

                    // Sign-out successful.
                  })
                  .catch((error) => {
                    console.log(error);
                    // An error happened.
                  });
            } else {
              this.$session.clear();
              firebase
                  .auth()
                  .signOut()
                  .then(() => {
                    this.$router.replace("/login").catch(() => {
                    });
                    console.log("Sign-out successful");
                    // Sign-out successful.
                  })
                  .catch((error) => {
                    console.log(error);
                    // An error happened.
                  });
            }
          });
    },
    calculate(equation, id) {
      //works only with 2 part equation with * or + or - or /
      // calculation[id]
      equation = JSON.parse(equation);
      var variables;
      if (equation.equation.includes("*")) {
        variables = equation.equation.split("*");
        variables[0] = variables[0].trim();
        variables[1] = variables[1].trim();
        equation.variablesnew.forEach((newvar) => {
          if (newvar[variables[0]]) {
            // newvar[variables[0]]
            equation.constants.forEach((constant) => {
              if (constant[variables[1]]) {
                console.log(
                    parseFloat(this.calculation[newvar[variables[0]]]),
                    parseFloat(constant[variables[1]])
                );
                var a =
                    parseFloat(this.calculation[newvar[variables[0]]]) *
                    parseFloat(constant[variables[1]]);
                this.calculation[id] = a;
              }
            });
          } else if (newvar[variables[1]]) {
            equation.constants.forEach((constant) => {
              if (constant[variables[0]]) {
                console.log(
                    parseFloat(newvar[variables[1]]),
                    parseFloat(constant[variables[0]])
                );
                this.calculation[id](
                    parseFloat(this.calculation[newvar[variables[1]]]) *
                    parseFloat(constant[variables[0]])
                );
              }
            });
          }
        });
      } else if (equation.equation.includes("+")) {
        variables = equation.equation.split("+");
        variables[0] = variables[0].trim();
        variables[1] = variables[1].trim();
        equation.variablesnew.forEach((newvar) => {
          if (newvar[variables[0]]) {
            // newvar[variables[0]]
            equation.constants.forEach((constant) => {
              if (constant[variables[1]]) {
                var a =
                    parseFloat(this.calculation[newvar[variables[0]]]) +
                    parseFloat(constant[variables[1]]);
                this.calculation[id] = a;
              }
            });
          } else if (newvar[variables[1]]) {
            equation.constants.forEach((constant) => {
              if (constant[variables[0]]) {
                this.calculation[id](
                    parseFloat(this.calculation[newvar[variables[1]]]) +
                    parseFloat(constant[variables[0]])
                );
              }
            });
          }
        });
      } else if (equation.equation.includes("-")) {
        variables = equation.equation.split("-");
        variables[0] = variables[0].trim();
        variables[1] = variables[1].trim();
        equation.variablesnew.forEach((newvar) => {
          if (newvar[variables[0]]) {
            // newvar[variables[0]]
            equation.constants.forEach((constant) => {
              if (constant[variables[1]]) {
                var a =
                    parseFloat(this.calculation[newvar[variables[0]]]) -
                    parseFloat(constant[variables[1]]);
                this.calculation[id] = a;
              }
            });
          } else if (newvar[variables[1]]) {
            equation.constants.forEach((constant) => {
              if (constant[variables[0]]) {
                this.calculation[id](
                    parseFloat(this.calculation[newvar[variables[1]]]) -
                    parseFloat(constant[variables[0]])
                );
              }
            });
          }
        });
      } else if (equation.equation.includes("/")) {
        variables = equation.equation.split("/");
        variables[0] = variables[0].trim();
        variables[1] = variables[1].trim();
        equation.variablesnew.forEach((newvar) => {
          if (newvar[variables[0]]) {
            // newvar[variables[0]]
            equation.constants.forEach((constant) => {
              if (constant[variables[1]]) {
                var a =
                    parseFloat(this.calculation[newvar[variables[0]]]) /
                    parseFloat(constant[variables[1]]);
                this.calculation[id] = a;
              }
            });
          } else if (newvar[variables[1]]) {
            equation.constants.forEach((constant) => {
              if (constant[variables[0]]) {
                this.calculation[id](
                    parseFloat(this.calculation[newvar[variables[1]]]) /
                    parseFloat(constant[variables[0]])
                );
              }
            });
          }
        });
      }
    },
  },
  computed: {},
  created() {
    this.checkLocal();
    console.log("application: ", this.$route.params.application);
    if (!this.$route.params.application) {
      // undefined
      this.application = this.$session.get("backup");
    } else {
      this.$session.set("backup", this.$route.params.application);
      this.application = this.$route.params.application;
    }
  },
  beforeCreate: function () {
    if (!this.$route.params.application) {
      // undefined
      this.$route.params.application = this.$session.get("backup");
    } else {
      // ok do nothing
    }
    let tempApp = this.$route.params.application;
    let tempOrdered = [];
    tempApp.data.fields.forEach((element) => {
      tempOrdered[element.index / 1000 - 1] = element;
    });
    this.$route.params.application.data.fields = tempOrdered;
  },
};
</script>

<style lang="scss" scoped>
.header {
  padding: 20px;
  background: #e6e6e6;
  box-shadow: 7px 6px 5px grey;
}
</style>
