<template>
  <div class="imageUploader container">
    <div class="row d-flex justify-content-start">
      <input
        class="custom-file-upload"
        type="file"
        accept="image/*"
        @change="
          (e) => {
            imageUpload(e);
          }
        "
        multiple
      />
      <img src="../assets/loading.gif" width="50px" v-if="loading" alt="" />
    </div>

    <div
      class="preview row d-flex justify-content-start"
      v-for="(img, index) in imagesPreview"
      :key="index"
      style="max-height: 60px"
    >
      <div class="col-2">
        <img :src="img" width="100px" height="60px" alt="" />
        <a-icon type="delete" @click="deleteImage(img)" />
      </div>
    </div>
  </div>
</template>

<script>
import "bootstrap/dist/css/bootstrap.min.css";
import { LocaleArabic, LocaleEnglish } from "../scripts/Locale";

// @ is an alias to /src
export default {
  name: "ImageUploader",
  components: {},
  props: {
    fieldData: Object,
    min: Number,
    max: Number,
    deletable: Boolean,
  },
  data() {
    return {
      locale: null,
      imagesPreview: [],
      loading: false,
    };
  },
  methods: {
    deleteImage(img) {
      for (let index = 0; index < this.imagesPreview.length; index++) {
        const element = this.imagesPreview[index];
        if (element == img) {
          if (index === 0) {
            this.imagesPreview.splice(index);
          } else {
            this.imagesPreview.splice(index, index);
          }
        }
      }
      this.$emit("imageUpload", this.imagesPreview);
    },
    imageUpload(e) {
      this.loading = true;
      let files = e.target.files;
      files.forEach((file) => {
        this.getBase64(file);
      });
    },
    getBase64(file) {
      // used for image upload it will trigger loading while it makes it as base 64 and turn loading off when added to array
      let files64 = new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
      files64.then((e) => {
        if (this.imagesPreview.length < this.max) {
          this.imagesPreview.push(e);
          this.$emit("imageUpload", this.imagesPreview);
        } else {
          this.$message.warning(this.locale.limitsreached);
        }
        this.loading = false;
        // this.images.push({ id: id, data: cleanE });
        // this.setLoader(false);
      });
    },
    checkLocal() {
      if (this.$session.get("lang") && this.$session.get("lang") == "ar") {
        this.locale = LocaleArabic;
      } else {
        this.locale = LocaleEnglish;
      }
    },
  },
  created() {
    this.checkLocal();
  },
};
</script>

<style lang="scss" scoped>
.preview {
  margin-top: 20px;
  margin-bottom: 5px;
  img {
    border-radius: 15px;
  }
  i {
    position: absolute;
    right: 65px;
    background: red;
    border-radius: 50%;
    transform: scale(1.25);
    padding: 2px;
    bottom: 50px;
  }
}
.custom-file-upload {
  border: 1px solid #ccc;
  display: inline-block;
  padding: 6px 12px;
  cursor: pointer;
}
</style>
